import React, { useRef } from "react";
import logoWhite from "../assets/logo_white.png";
import Particelle from "./Particelle";
import { Curves } from "three/examples/jsm/curves/CurveExtras";
import DynamicIcon from "./DynamicIconWhite";
export default function HomeBlue(props) {
  const particelle = useRef(null);
  const textLeft = useRef(null);
  const textRight = useRef(null);
  const textUnder = useRef(null);

  const handleMouseOver = (event) => {
    //ssprops.changeHover()
    particelle.current.classList.remove("hidden");
    textLeft.current.classList.remove("hidden");
    textUnder.current.classList.remove("hidden");
    textRight.current.classList.remove("hidden");
  };
  const handleMouseOut = (event) => {
    particelle.current.classList.add("hidden");
    textLeft.current.classList.add("hidden");
    textUnder.current.classList.add("hidden");
    textRight.current.classList.add("hidden");
  };
  return (
    <div
      className="homeBlue"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <header className="grid">
        <a href="#">
          <img src={logoWhite} className="logo"></img>
          <h5 className="textLogo">FINANCIAL ENGINEERING</h5>
        </a>
        <nav>
          <span onClick={props.cambia} className={"noDesktop navIconHome"}>
            <DynamicIcon
              text={props.text.lenguage}
              type={"language"}
            ></DynamicIcon>
          </span>
        </nav>
      </header>
      <div className="parentStart" onClick={() => props.changePage("blue")}>
        <div className="childStart part" ref={particelle}>
          <Particelle
            geometry={new Curves.TrefoilKnot(35).getPoints(70)}
            fov={
              props.screen == "Desktop"
                ? 95
                : props.screen == "Mobile"
                ? 95
                : 95
            }
            count={
              props.screen == "Desktop"
                ? 220
                : props.screen == "Mobile"
                ? 170
                : 220
            }
            lineWidth={
              props.screen == "Desktop"
                ? 0.35
                : props.screen == "Mobile"
                ? 0.35
                : 0.25
            }
            dashArray={
              props.screen == "Desktop"
                ? 0.0065
                : props.screen == "Mobile"
                ? 0.0065
                : 0.0065
            }
            rotation={0}
          ></Particelle>
        </div>
        <div className="childStart">
          <div className="homeTitle">
            <h1>{props.text.title}</h1>
            <p>{props.text.subtitle} </p>
            <div className="leftPopUp hidden" ref={textLeft}>
              {props.text.portfolio}
            </div>
            <div className="underPopUp hidden" ref={textUnder}>
              {props.text.allocation}
            </div>
            <div className="rightPopUp hidden" ref={textRight}>
              {props.text.ideas}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
