import React, { useMemo, useRef } from "react";
import * as meshline from "threejs-meshline";
import { extend, Canvas, useFrame, useThree } from "react-three-fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Curves } from "three/examples/jsm/curves/CurveExtras";
import { EffectComposer } from "three/examples/jsm//postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm//postprocessing/RenderPass";
import { GlitchPass } from "three/examples/jsm//postprocessing/GlitchPass";
extend(meshline);
extend({ OrbitControls });
extend({ EffectComposer, RenderPass, GlitchPass });

/* Controls Compoent */
const Controls = () => {
  const orbitRef = useRef();
  const { camera, gl } = useThree();

  useFrame(() => {
    orbitRef.current.update();
  });
  return (
    <orbitControls
      args={[camera, gl.domElement]}
      ref={orbitRef}
      enableKeys={false}
    />
  );
};

function Fatline({ curve, width, color, speed, dashArray, rotation }) {
  const material = useRef();
  const mesh = useRef();

  useFrame((state, delta, xrFrame) => {
    material.current.uniforms.dashOffset.value -= speed;
    mesh.current.rotation.x += 0.002;
    mesh.current.rotation.y += 0.001;
  });

  return (
      
     
      <mesh
        ref={mesh}
        rotation={[rotation == 0 ? 2 : 0, Math.random() * Math.PI, rotation]}
      >
        <meshLine attach="geometry" vertices={curve} />
        <meshLineMaterial
          attach="material"
          ref={material}
          transparent
          depthTest={false}
          lineWidth={width}
          color={color}
          dashArray={dashArray}
          dashRatio={0.96}
        />
      </mesh>
  );
}

const lineWidth = 0.2;
const count = 150;

function Lines({ count, colors, geometry, lineWidth, dashArray, rotation }) {
  const lines = useMemo(
    () =>
      new Array(count).fill().map(() => {
        const curve = geometry;

        return {
          color: colors[parseInt(colors.length * Math.random())],
          width: lineWidth,
          speed: Math.max(0.0001, 0.0003 * Math.random()),
          curve,
        };
      }),
    [colors, count]
  );
  const cachedValue = useMemo(
    () =>
      lines.map((props, index) => (
        <Fatline
          key={index}
          dashArray={dashArray}
          rotation={rotation}
          {...props}
        />
      )),
    []
  );
  return cachedValue;
}
function Particelle(props) {
  return (
    <Canvas camera={{ position: [50, 100, 100], fov: props.fov }}>
      <Lines
        count={props.count}
        colors={["#F48A1E"]}
        geometry={props.geometry}
        lineWidth={props.lineWidth}
        dashArray={props.dashArray}
        rotation={props.rotation}
      />
    </Canvas>
  );
}
export default React.memo(Particelle);
