import React, { useState, forwardRef } from "react";
// import video from "../assets/placeholder.mp4";
import AP from "../assets/AP_01.gif";
import APi from "../assets/AP_01.jpg";

import FF from "../assets/FF_01.gif";
import FFi from "../assets/FF_01.jpg";

import PT from "../assets/PT_01.gif";
import PTi from "../assets/PT_01.jpg";

import SP from "../assets/SP_01.gif";
import SPi from "../assets/SP_01.jpg";

import SS from "../assets/SS_01.gif";
import SSi from "../assets/SS_01.jpg";

import MW from "../assets/MW_01.gif";
import MWi from "../assets/MW_01.jpg";

import GD from "../assets/GD_01.gif";
import GDi from "../assets/GD_01.jpg";

import AC from "../assets/AC_01.gif";
import ACi from "../assets/AC_0001.jpg";

import Card from "./Card";

const Company = forwardRef((props, ref) => {
  return (
    <>
      <p id="Company" ref={ref}>
        {props.text.subParagraph}
      </p>

      <ul className="ulCards">
        <Card
          name="Stefano Pezzoli"
          position="The Playmaker"
          deg="Lic. Oec. HSG"
          gif={SP}
          still={SPi}
        />
        <br></br>
        <Card
          name="Stefano Sampietro"
          position="The Quant Mind"
          deg="Phd"
          gif={SS}
          still={SSi}
        />
        <Card
          name="Paolo Tenconi"
          position="The Mind Behind (the platform)"
          deg="Phd"
          gif={PT}
          still={PTi}
        />
        <Card
          name="Marcello Waller"
          position="The Pricing Machine"
          deg="MscIBD"
          gif={MW}
          still={MWi}
        />
        <Card
          name="Andrea Ciocca"
          position="The Trader Corner"
          deg="MSc Finance"
          gif={AC}
          still={ACi}
        />
        <Card
          name="Andreas Passano"
          position="The Financial IT Support"
          deg="MSc Finance"
          gif={AP}
          still={APi}
        />
        
        <Card
          name="Giancarlo D’Oria"
          position="The Fund corner"
          // deg='"---"'
          gif={GD}
          still={GDi}
        />
      </ul>
      <br></br>
    </>
  );
});
export default Company;
