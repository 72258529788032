import React, { forwardRef } from "react";

const Margherita = forwardRef((props, ref) => {
  {
    console.log(props)
    const linkStyles = {
      textDecoration: "underline",
      position: "unset",
      borderBottom: "none",
      paddingLeft: 0,
      margin: 0,
      display: "unset",
      fontSize: "1em",
      letterSpacing: "0.5px",
      lineHeight: 1.15,
      fontWeight: 400,
    };
    return (
      <>
        <h1 ref={ref}>{props.text.title}</h1>
        <p id="Margherita" >
          {props.text.text} <a
                href="http://fondazionemargherita.ch"
                id="linkMargherita"
                target="_blank"
                style={linkStyles}
              >
              {props.text.title}</a>
              .
        </p>
      </>
    );
  }
});
export default Margherita;
