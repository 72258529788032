const testo = {
  it: {
    title: "Piattaforma cliente",
    titleclose: "Piattaforma \n cliente",
    portfolio: "Il tuo portafoglio",
    underPortfolio: "Elenco prodotti e stato attuale",
    allocation: "La tua allocazione",
    underAllocation: "Prodotti per categoria",
    ideas: "Idee correnti",
    underIdeas: "Dove investire",
    subtitle:
      "La piattaforma di On finance offre ai nostri clienti un ambiente dedicato e personalizzabile.",
    titleAboutMargherita: "Fondazione Margherita",
    lenguage: "IT",
    titlewhite: "Il mondo On finance",
    titlewhiteclose: "Il mondo \n On finance",
    subtitlewhite:
      "On finance è una società indipendente focalizzata nell’offerta di ingegneria finanziaria",
    Focus: {
      titleAbout: "Focus",
      desctiptionAbout:
        "Siamo una società indipendente specializzata nell’offerta di ingegneria finanziaria e dedicata a gestori patrimoniali e investitori istituzionali. Creiamo soluzioni di investimento personalizzate, offriamo servizi di risk management e monitoriamo fino a scadenza la valorizzazione dei prodotti emessi.",
    },
    Complementary: {
      titleAbout: "Complementare",
      desctiptionAbout:
        "Il nostro unico obiettivo è creare valore aggiunto attraverso soluzioni di financial engineering. Lavorando in partnership con i nostri clienti, offriamo un servizio personalizzato e costruiamo con sinergia idee d’investimento, differenziandoci dalla semplice distribuzione di prodotti di terzi. On finance offre, con la propria indipendenza, un prezioso servizio complementare all’asset management.",
    },
    Creativity: {
      titleAbout: "Creatività",
      desctiptionAbout:
        "La nostra esperienza ventennale nel servire clienti istituzionali, unita un solido background quantitativo, ci permette di identificare e costruire soluzioni d’investimento innovative e personalizzate che rispondono alle più complesse esigenze.",
    },
    FinancialEngineering: {
      titleAbout: "Ingegneria Finanziaria",
      desctiptionAbout:
        'L’ingegneria finanziaria ottimizza e completa gli investimenti tradizionali con l’uso di derivati semplici e complessi. Permette di estrarre valore dalla dislocazione dei mercati e ridurre il rischio presente nei portafogli tradizionali. Attraverso l’ingegneria finanziaria è possibile, inoltre, diversificare il portafoglio tramite investimenti a bassa correlazione rispetto alle asset class "classiche".',
    },
    Implementation: {
      titleAbout: "Implementazione",
      desctiptionAbout:
        "Generare idee senza la capacità d'implementarle con efficienza serve a poco. \n On finance può contare su rapporti di fiducia e su collaborazioni con le migliori banche d’investimento e market makers internazionali, nonché su modelli di pricing proprietari. Inoltre, On finance ha sviluppato internamente una piattaforma flessibile per la gestione dei prodotti outstanding.",
    },
    BusinessArea: {
      titleAbout: "Business Area",
      Equity: "Equity",
      Rates: "Rates",
      Commodity: "Commodity",
      CreditRisk: "Credit Risk",
    },
    Margherita: {
      title: "Fondazione Margherita",
      text: "On finance dalla sua costituzione nel 2000 ha sempre avuto il desiderio, visione e ambizione di essere una impact company e operare nel rispetto di un approccio ESG: un accordo tra gli azionisti prevede infatti che una importante percentuale fissa degli utili venga destinata a creare sviluppo o aiuto a chi ha bisogno e dove necessario. \n \n Nel 2004 per meglio istituzionalizzare questo contributo d’impatto, On finance ha costituito la Fondazione Margherita e la gestisce direttamente con il supporto di esperti di settore, indipendenti. \n \n Ad oggi oltre 100 iniziative nel mondo sono state sostenute dalla",
    },
    Company: "Società",
    Contact: "Contatto",
    subParagraph:
      "On finance è riconosciuta in Svizzera e all’estero quale pioniere nel financial engineering con oltre 20 anni di esperienza.",
    Goback: "Torna indietro",
  },
  en: {
    title: "Client’s Platform",
    titleclose: "Client’s  \n Platform",
    portfolio: "Your Portfolio",
    underPortfolio: "Product list and current status",
    allocation: "Your Allocation",
    underAllocation: "Products by categories",
    ideas: "Current Ideas",
    underIdeas: "Where to invest next",
    subtitle:
      "On finance's platform offers our clients a dedicated and customisable environment.",
    titleAboutMargherita: "Margheritas Foundation",
    lenguage: "EN",

    titlewhite: " About On finance",
    titlewhiteclose: " About \n On finance",
    subtitlewhite:
      "On finance is an independent advisory company focused on financial engineering.",
    Focus: {
      titleAbout: "Focus",
      desctiptionAbout:
        "We are an independent advisory company focused on delivering bespoke financial engineering solutions to asset managers. We design investment ideas, provide risk management support and monitor the life cycle of all our products.",
    },
    Complementary: {
      titleAbout: "Complementarity",
      desctiptionAbout:
        "As an independent platform our only objective is adding value to our clients. \n By adopting a partnership approach with all our clients we can provide tailor made services as opposed to selling products. On finance is the independent meeting point for asset managers and financial engineering.",
    },
    Creativity: {
      titleAbout: "Creativity",
      desctiptionAbout:
        "Our 20+ years of experience in servicing institutional clients combined with a strong and rigorous Quant background, allows us to design innovative investment solutions and respond to the most complex needs.",
    },
    FinancialEngineering: {
      titleAbout: "Financial Engineering",
      desctiptionAbout:
        "Designed to enhance the traditional investment scheme through the use of simple and complex derivatives. It allows portfolio managers to extract value from market dislocation and to reduce or eliminate risks from investment portfolios. Financial engineering also offers diversification opportunities and uncorrelated investments to traditional asset classes.",
    },
    Implementation: {
      titleAbout: "Implementation",
      desctiptionAbout:
        "Idea creation is nothing without the ability to execute efficiently.  \n  On finance enjoys a multi-decade relationship with every leading international market maker combined with our proprietary pricing models.",
    },
    BusinessArea: {
      titleAbout: "Business Area",
      Equity: "Equity",
      Rates: "Rates",
      Commodity: "Commodity",
      CreditRisk: "Credit Risk",
    },

    Margherita: {
      title: "Fondazione Margherita",
      text: "Since its establishment in early 2000, On Finance has always had the desire, vision and ambition of being an impact company and operating in compliance with an ESG approach: in fact, an agreement between the shareholders provides that an important fixed percentage of the profits is destined to create development or help those who have need and where necessary. \n \n In 2004, to better institutionalize this impactful contribution, On finance set up the Fondazione Margherita and manages it directly with the support of independent sector experts. \n \n To date, over 100 initiatives around the world have been supported by the",
    },

    Company: "Company",
    Contact: "Contact",
    subParagraph:
      "On finance is recognized in Switzerland as a pioneer in financial engineering with over 20 years of experience.",
    Goback: "Go back",
  },
};
export default testo;
