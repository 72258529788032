import React, { useRef, useState, useEffect } from "react";
import Company from "./Company";
import Contact from "./Contact";
import Margherita from "./Margherita";
function MainMobileWhite(props) {
  const [elemento, setElemento] = useState("null");
  const company = useRef(null);
  const contact = useRef(null);
  const margherita = useRef(null);
  useEffect(() => {
    setElemento(props.elem);
    if (props.elem === "Company") {
      const e = company;
      setTimeout(
        () => company.current.scrollIntoView({ behavior: "smooth" }),
        30
      );
    }
    if (props.elem === "Contact") {
      const e = contact;
      setTimeout(
        () => contact.current.scrollIntoView({ behavior: "smooth" }),
        30
      );
    }

    if (props.elem === "Margherita") {
      const e = margherita;
      setTimeout(
        () => margherita.current.scrollIntoView({ behavior: "smooth" }),
        30
      );
    }
  }, [props]);
  const linkStyles = {
    textDecoration: "underline",
    position: "unset",
    borderBottom: "none",
    paddingLeft: 0,
    margin: 0,
    display: "unset",
    fontSize: "1em",
    letterSpacing: "0.5px",
    lineHeight: 1.15,
    fontWeight: 400,
  };
  return (
    <>
      <h1>{props.text.titlewhite}</h1>
      <nav className="navWhite">
        <a
          onClick={() => {
            setElemento("Focus");
          }}
          className={elemento === "Focus" ? "active" : undefined}
        >
          {props.text.Focus.titleAbout}
        </a>
        {elemento === "Focus" && (
          <p className="textBetweenLink noDesktop">
            {props.text.Focus.desctiptionAbout}
          </p>
        )}
        <a
          onClick={() => {
            setElemento("FinancialEngineering");
          }}
          className={elemento === "FinancialEngineering" ? "active" : undefined}
        >
          {props.text.FinancialEngineering.titleAbout}
        </a>
        {elemento === "FinancialEngineering" && (
          <p className="textBetweenLink noDesktop">
            {props.text.FinancialEngineering.desctiptionAbout}
          </p>
        )}
        <a
          onClick={() => {
            setElemento("Complementary");
          }}
          className={elemento === "Complementary" ? "active" : undefined}
        >
          {props.text.Complementary.titleAbout}
        </a>
        {elemento === "Complementary" && (
          <p className="textBetweenLink noDesktop">
            {props.text.Complementary.desctiptionAbout}
          </p>
        )}
        <a
          onClick={() => {
            setElemento("Creativity");
          }}
          className={elemento === "Creativity" ? "active" : undefined}
        >
          {props.text.Creativity.titleAbout}
        </a>
        {elemento === "Creativity" && (
          <p className="textBetweenLink noDesktop">
            {props.text.Creativity.desctiptionAbout}
          </p>
        )}
        <a
          onClick={() => {
            setElemento("Implementation");
          }}
          className={elemento === "Implementation" ? "active" : undefined}
        >
          {props.text.Implementation.titleAbout}
        </a>
        {elemento === "Implementation" && (
          <p className="textBetweenLink noDesktop">
            {props.text.Implementation.desctiptionAbout}
          </p>
        )}

        {/* 
        <a
          onClick={() => {
            setElemento("Margherita");
          }}
          className={elemento === "Margherita" ? "active" : undefined}
          id={"margherita"}
        >
          {props.text.Margherita.titleAbout}
        </a>
       {elemento === "Margherita" && (
          <p className="textBetweenLink noDesktop">
            {props.text.Margherita.text}
            <span>
              <a
                href="http://fondazionemargherita.ch"
                id="linkMargherita"
                style={linkStyles}
                target="_blank"
              >
                Fondazione Margherita
              </a>
            </span>
            {props.text.Margherita.text2}
          </p>
        )} */}
      </nav>
      <ul>
        {elemento === "Contact" && (
          <Contact ref={contact} text={props.text}></Contact>
        )}
        {elemento === "Company" && (
          <Company ref={company} text={props.text}></Company>
        )}

        {elemento === "Margherita" && (
          <Margherita ref={margherita} text={props.text.Margherita}></Margherita>
        )}
      </ul>
    </>
  );
}
export default MainMobileWhite;
