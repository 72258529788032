import { useState } from "react";

function Card({ name, position, deg, gif, still }) {
  const [play, setPlay] = useState(false);
  return (
    <li>
      <div className="card">
        <div
          onMouseEnter={() => setPlay(true)}
          onMouseLeave={() => setPlay(false)}
        >
          {/* <video src={video} className="cardImg" loop muted></video> */}
          {play ? (
            <img src={gif} alt="Animated GIF" className="cardImg" />
          ) : (
            <img src={still} alt="Still GIF" className="cardImg" />
          )}
        </div>

        <div className="cardText">
          <p>
            <b> {name}</b>
          </p>
          <p>{position}</p>
          <p>{deg}</p>
        </div>
      </div>
    </li>
  );
}

export default Card;
