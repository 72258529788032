import React, { useRef, useState, useCallback, useEffect } from "react";
import SlideBlue from "./component/SlideBlue";
import SlideWhite from "./component/SlideWhite";
import HomeBlue from "./component/HomeBlue";
import HomeWhite from "./component/HomeWhite";
import "./App.css";
import "./SlideWhite.css";
import "./SlideBlu.css";
import testo from "./component/text";
import { Helmet } from "react-helmet";
import Favicon from "react-favicon";
import CookieConsent from "react-cookie-consent";
function App() {
  const divBlu = useRef(null);
  const divWhite = useRef(null);
  const general = useRef(null);

  const [pagina, setPagina] = useState("home");
  const [lingua, setLingua] = useState("en");
  const changeLenguage = useCallback(() => {
    setLingua(l => {
      if (l === "en") {
        return "it";
      } else {
        return "en";
      }
    });
  }, [lingua]);

  const changePage = useCallback(
    param => {
      setPagina(p => {
        return param;
      });
    },
    [pagina]
  );

  const [heightBorder, setHeightBorder] = useState();
  const [mobile, setMobile] = useState();
  /*
    mobile < 768
    desktop<1200
    desktopLarge>1200*/

  const handleResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };
  const handleResizeScreen = () => {
    if (window.innerWidth < 768) {
      setMobile("Mobile");
    } else if (window.innerWidth < 1200) {
      setMobile("Desktop");
    } else {
      setMobile("DesktopLarge");
    }
  };
  useEffect(() => {
    handleResizeScreen();
    window.addEventListener("resize", handleResizeScreen);
    const delta = general.current.clientHeight - window.innerHeight;
    setHeightBorder(delta);
    if (delta > 0) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
      window.addEventListener("resize", handleResize);
    }

    return () => {
      if (heightBorder > 0) {
        window.removeEventListener("resize", handleResize);
      }
    };
  }, []);

  /*codice per gestire l'indirizzamento nei link */
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Leggi i valori dei singoli parametri
    const destinazione = urlParams.get("page");
    const language = urlParams.get("language");
    if (destinazione !== null) {
      if (destinazione == "AboutOnfinance") {
        setPagina("white");
      } else if (destinazione == "Platform") {
        setPagina("blue");
      }
    }
    if (language != null) {
      setLingua("it");
    }
  }, []);

  useEffect(() => {
    if (mobile === "Mobile") {
      divBlu.current.style.width = "100vw";
      divBlu.current.style.whiteSpace = "normal";
      divWhite.current.style.width = "100vw";
      divWhite.current.style.whiteSpace = "normal";
      if (pagina === "home") {
        divBlu.current.style.height = "calc(var(--vh, 1vh) * 55)";
        divWhite.current.style.height = "calc(var(--vh, 1vh) * 45)";
      } else if (pagina === "white") {
        divWhite.current.style.height = "calc(var(--vh, 1vh) * 90)";

        divBlu.current.style.height = "calc(var(--vh, 1vh) * 10)";
      } else {
        divBlu.current.style.height = "calc(var(--vh, 1vh) * 90)";
        divWhite.current.style.height = "calc(var(--vh, 1vh) * 10)";
      }
    } else {
      divBlu.current.style.height = "100vh";
      divWhite.current.style.height = "100vh";
      if (pagina === "home") {
        divBlu.current.style.width = "50vw";
        divWhite.current.style.width = "50vw";
        //divWhite.current.style.overflow = "hidden"
        divBlu.current.style.overflow = "hidden";
      } else if (pagina === "white") {
        divWhite.current.style.width = "88vw";
        divWhite.current.style.whiteSpace = "normal";
        divWhite.current.style.cursor = "auto";
        divWhite.current.style.overflow = "scroll";

        divBlu.current.style.width = "12vw";
        //divBlu.current.style.overflow = "hidden"
        divBlu.current.style.cursor = "pointer";
        divBlu.current.style.whiteSpace = "pre-line";
      } else {
        divBlu.current.style.width = "88vw";
        divBlu.current.style.whiteSpace = "normal";
        divBlu.current.style.cursor = "auto";
        //divBlu.current.style.overflow = "auto"

        divWhite.current.style.width = "12vw";
        //divWhite.current.style.overflow = "hidden"
        divWhite.current.style.cursor = "pointer";
        divWhite.current.style.whiteSpace = "pre-line";
      }
    }
  }, [pagina, mobile]);
  /*useEffect(() => {
    setPagina("home")
  }, [mobile]);
  if(pagina == "loading"){
    setPagina("home")
    return (
      <div style={{backgroundColor: 'white',}}></div>
    )
  }*/
  return (
    <div ref={general} className="General">
      <div className="CookieConsent">
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          e="cookie"
          expires={300}
          style={{ background: "#172242" }}
          buttonStyle={{
            background: "#172242",
            color: "#f48a1e",
            fontSize: "16px",
          }}
          overlay={true}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </div>

      <Favicon url="favicon.ico" />
      <Helmet>
        <meta charSet="utf-8" />
        <title>On finance website</title>
        <link rel="canonical" href="http://www.onfinance.ch" />
        <meta
          name="description"
          content="On finance is an independent advisory company focused on financial engieering."
        />
      </Helmet>
      <div ref={divBlu} className="blue">
        {pagina == "home" && (
          <HomeBlue
            text={lingua == "en" ? testo.en : testo.it}
            cambia={changeLenguage}
            changePage={changePage}
            screen={mobile}
          ></HomeBlue>
        )}
        {pagina == "white" && (
          <Closeblue
            changePage={changePage}
            text={lingua == "en" ? testo.en : testo.it}
          ></Closeblue>
        )}
        {pagina == "blue" && (
          <SlideBlue
            text={lingua == "en" ? testo.en : testo.it}
            cambia={changeLenguage}
            changePage={changePage}
            screen={mobile}
          ></SlideBlue>
        )}
      </div>
      <div ref={divWhite} className="white">
        {pagina == "home" && (
          <HomeWhite
            text={lingua == "en" ? testo.en : testo.it}
            cambia={changeLenguage}
            changePage={changePage}
            screen={mobile}
          ></HomeWhite>
        )}
        {pagina == "blue" && (
          <Closewhite
            text={lingua == "en" ? testo.en : testo.it}
            changePage={changePage}
            heightBorder={heightBorder}
          ></Closewhite>
        )}
        {pagina == "white" && (
          <SlideWhite
            text={lingua == "en" ? testo.en : testo.it}
            cambia={changeLenguage}
            changePage={changePage}
            screen={mobile}
          ></SlideWhite>
        )}
      </div>
    </div>
  );
}

function Closeblue(props) {
  return (
    <div
      className="closeBlue closeTitle"
      onClick={() => props.changePage("blue")}
    >
      {props.text.titleclose}
    </div>
  );
}

function Closewhite(props) {
  return (
    <div
      className="closeWhite closeTitle textBlu"
      onClick={() => props.changePage("white")}
    >
      {props.text.titlewhiteclose}
    </div>
  );
}

export default App;
