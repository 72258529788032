import React, { useState } from "react";
import globeBlue from "../assets/globeBlue.svg";
import globeOrange from "../assets/orange/globeOrange.svg";
import contactBlue from "../assets/contactBlue.svg";
import contactOrange from "../assets/orange/contactOrange.svg";
import margheritaBlue from "../assets/margheritaBlue.svg";
import margheritaOrange from "../assets/margheritaOrange.svg";
import company from "../assets/company.svg";
import companyOrange from "../assets/orange/companyOrange.svg";

export default function DynamicIcon(props) {
  const [hover, setHover] = useState(false);
  const handleMouseOver = event => {
    setHover(true);
  };
  const handleMouseOut = event => {
    setHover(false);
  };
  return (
    <a
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      href={
        props.type === "language"
          ? "#"
          : props.screen === "Mobile"
          ? props.type === "Company"
            ? "#Company"
            : "#contact"
          :  props.type === "Margherita" ? "#Margherita": "#company"
      }
      className={hover ? "activeOrange" : undefined}
    >
      <img
        src={(() => {
          if (props.type === "language") {
            return hover ? globeOrange : globeBlue;
          }
          if (props.type == "Company") {
            return hover ? companyOrange : company;
          }

          if (props.type == "Margherita") {
            return hover ? margheritaOrange : margheritaBlue;
          }

          return hover ? contactOrange : contactBlue;
        })()}
        className="icon"
      ></img>
      {props.text}
    </a>
  );
}
