import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import logoWhite from "../assets/logo_white.png";
import DynamicIcon from "./DynamicIconWhite";
import Particelle from "./Particelle";
import { Curves } from "three/examples/jsm/curves/CurveExtras";

export default function SlideBlue(props) {
  const portfoglio = useRef(null);
  const allocation = useRef(null);
  const idea = useRef(null);
  const portfoglioUnder = useRef(null);
  const allocationUnder = useRef(null);
  const ideaUnder = useRef(null);
  //const [elemento, setElemento] = useState("null");
  const handleMouseOver = (event) => {
    if (props.screen == "Desktop" || props.screen == "DesktopLarge") {
      if (event.currentTarget.matches("#portfolio")) {
        portfoglio.current.classList.remove("hidden");
        portfoglioUnder.current.classList.remove("hidden");
        //setElemento("portfolio");
      } else if (event.currentTarget.matches("#allocation")) {
        allocation.current.classList.remove("hidden");
        allocationUnder.current.classList.remove("hidden");
        //setElemento("allocation");
      } else if (event.currentTarget.matches("#ideas")) {
        idea.current.classList.remove("hidden");
        ideaUnder.current.classList.remove("hidden");
        //setElemento("ideas");
      }
    }
  };
  const handleMouseOut = (event) => {
    if (props.screen == "Desktop" || props.screen == "DesktopLarge") {
      portfoglio.current.classList.add("hidden");
      allocation.current.classList.add("hidden");
      idea.current.classList.add("hidden");
      ideaUnder.current.classList.add("hidden");
      portfoglioUnder.current.classList.add("hidden");
      allocationUnder.current.classList.add("hidden");
    }
  };
  const callBackScrollA = (entries) => {
    const [entry] = entries;
    //entries.classList.remove("hidden")
    if (entry.isIntersecting == true) {
      portfoglio.current.classList.remove("hidden");
      portfoglioUnder.current.classList.remove("hidden");
    } else {
      portfoglio.current.classList.add("hidden");
      portfoglioUnder.current.classList.add("hidden");
    }
  };
  const callBackScrollB = (entries) => {
    const [entry] = entries;
    //entries.classList.remove("hidden")
    if (entry.isIntersecting == true) {
      allocation.current.classList.remove("hidden");
      allocationUnder.current.classList.remove("hidden");
    } else {
      allocation.current.classList.add("hidden");
      allocationUnder.current.classList.add("hidden");
    }
  };
  const callBackScrollC = (entries) => {
    const [entry] = entries;
    //entries.classList.remove("hidden")
    if (entry.isIntersecting == true) {
      idea.current.classList.remove("hidden");
      ideaUnder.current.classList.remove("hidden");
    } else {
      idea.current.classList.add("hidden");
      ideaUnder.current.classList.add("hidden");
    }
  };
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.7,
  };
  const optionsb = {
    root: null,
    rootMargin: "0px",
    threshold: 0.98,
  };
  useEffect(() => {
    if (props.screen == "Mobile") {
      const observerA = new IntersectionObserver(callBackScrollA, optionsb);
      const observerB = new IntersectionObserver(callBackScrollB, optionsb);
      const observerC = new IntersectionObserver(callBackScrollC, optionsb);
      if (portfoglio.current) observerA.observe(portfoglio.current);
      observerB.observe(allocation.current);
      observerC.observe(idea.current);

      return () => {
        if (portfoglio.current) observerA.unobserve(portfoglio.current);
        if (allocation.current) observerB.unobserve(allocation.current);
        if (idea.current) observerC.unobserve(idea.current);
        /*observer.unobserve(allocation.current)
        observer.unobserve(idea.current)*/
      };
    }
  });
  const year = new Date().getFullYear();

  return (
    <div className="slideBlue">
      <header className="grid">
        <a href="#" onClick={() => props.changePage("home")}>
          <img src={logoWhite} className="logo logoHome"></img>
          <h5 className="textLogo">FINANCIAL ENGINEERING</h5>
        </a>
        <nav>
          <div className="navIcon">
            <span onClick={props.cambia}>
              <DynamicIcon
                text={props.text.lenguage}
                type={"language"}
              ></DynamicIcon>
            </span>
            <DynamicIcon text={"Login"} type={"login"}></DynamicIcon>
          </div>
        </nav>
      </header>
      <main className="grid">
        <h1>{props.text.title}</h1>
        <div className="platformLinks">
          <div
            className="parentHome"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            id="portfolio"
          >
            <div className="childHome partHome hidden" ref={portfoglio}>
              <Particelle
                geometry={new Curves.TrefoilKnot(35).getPoints(80)}
                fov={
                  props.screen == "Desktop"
                    ? 95
                    : props.screen == "Mobile"
                    ? 95
                    : 95
                }
                count={
                  props.screen == "Desktop"
                    ? 180
                    : props.screen == "Mobile"
                    ? 100
                    : 95
                }
                lineWidth={
                  props.screen == "Desktop"
                    ? 0.65
                    : props.screen == "Mobile"
                    ? 0.9
                    : 0.45
                }
                dashArray={
                  props.screen == "Desktop"
                    ? 0.0107
                    : props.screen == "Mobile"
                    ? 0.0107
                    : 0.0097
                }
                rotation={3}
              />
            </div>
            <div className="childHome">
              <div className="element">
                <a
                  href="https://platform.onfinance.ch/products_devel/?portfolio=true"
                  target="_blank"
                >
                  {props.text.portfolio}
                </a>

                <div
                  className="underelement selectElement hidden"
                  ref={portfoglioUnder}
                >
                  {props.text.underPortfolio}
                </div>
              </div>
            </div>
          </div>
          <div
            className="parentHome"
            id="allocation"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div className="childHome  partHome hidden" ref={allocation}>
              <Particelle
                geometry={new Curves.TorusKnot(20).getPoints(80)}
                fov={
                  props.screen == "Desktop"
                    ? 52
                    : props.screen == "Mobile"
                    ? 50
                    : 50
                }
                count={
                  props.screen == "Desktop"
                    ? 100
                    : props.screen == "Mobile"
                    ? 40
                    : 50
                }
                lineWidth={
                  props.screen == "Desktop"
                    ? 0.45
                    : props.screen == "Mobile"
                    ? 0.7
                    : 0.35
                }
                dashArray={
                  props.screen == "Desktop"
                    ? 0.0065
                    : props.screen == "Mobile"
                    ? 0.0065
                    : 0.0065
                }
                rotation={0}
              />
            </div>
            <div className="childHome">
              <div className="element">
                <a
                  href="https://platform.onfinance.ch/products_devel/?allocation=true"
                  target="_blank"
                >
                  {props.text.allocation}
                </a>

                <div
                  className="underelement selectElement hidden"
                  ref={allocationUnder}
                >
                  {props.text.underAllocation}
                </div>
              </div>
            </div>
          </div>
          <div
            className="parentHome"
            id="ideas"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
          >
            <div className="childHome  partHome hidden" ref={idea}>
              <Particelle
                geometry={new Curves.DecoratedTorusKnot4b(45).getPoints(150)}
                fov={60} //props.screen=="Desktop" ? 68 : (props.screen=="Mobile" ? 190 : 68)
                count={
                  props.screen == "Desktop"
                    ? 180
                    : props.screen == "Mobile"
                    ? 80
                    : 80
                }
                lineWidth={
                  props.screen == "Desktop"
                    ? 0.3
                    : props.screen == "Mobile"
                    ? 0.5
                    : 0.3
                }
                dashArray={
                  props.screen == "Desktop"
                    ? 0.013
                    : props.screen == "Mobile"
                    ? 0.013
                    : 0.01
                }
                rotation={2}
              />
            </div>
            <div className="childHome">
              <div className="element last">
                <a
                  href="https://platform.onfinance.ch/products_devel/?recent=true"
                  target="_blank"
                >
                  {props.text.ideas}
                </a>

                <div
                  className="underelement selectElement hidden"
                  ref={ideaUnder}
                >
                  {props.text.underIdeas}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer>
        <p>
          © {year} - On finance SA -
          <a href="mailto:ask@onfinance.ch"> ask@onfinance.ch</a> -
          <a href="https://custerwaller.com/" target="_blank">
            {" "}
            designed by cw
          </a>
        </p>
      </footer>
    </div>
  );
}
//export default React.useMemo(SlideBlue)
