import React, { useRef, useState, useCallback, useEffect } from "react";
import logoBlu from "../assets/logo_blue.png";
import globeBlue from "../assets/globeBlue.svg";
import contactBlue from "../assets/contactBlue.svg";
import DynamicIcon from "./DynamicIcon";
import Company from "./Company";
import Particelle from "./Particelle";
import { Curves } from "three/examples/jsm/curves/CurveExtras";
import close from "../assets/close.png";
import MainMobileWhite from "./MainMobileWhite";
import Contact from "./Contact";
import Margherita from "./Margherita";
export default function SlideWhite(props) {
  const portfoglio = useRef(null);
  const [elemento, setElemento] = useState("null");
  const year = new Date().getFullYear();
  const closeModal = useCallback(() => {
    setElemento(() => {
      return "null";
    });
  }, [elemento]);

  return (
    <div className="about slideWhite">
      <header className="grid">
        <a href="/#">
          <img
            src={logoBlu}
            className="logo"
            onClick={() => props.changePage("home")}
          ></img>
          <h5 className="textLogo">FINANCIAL ENGINEERING</h5>
        </a>
        <nav>
          <div className="navIcon">
            <span
              onClick={() => {
                setElemento("Company");
              }}
            >
              <DynamicIcon
                text={props.text.Company}
                type={"Company"}
                screen={props.screen}
              ></DynamicIcon>
            </span>

            <span
              onClick={() => {
                setElemento("Contact");
              }}
            >
              <DynamicIcon 
                text={props.text.Contact}
                type={"Contact"}
                screen={props.screen}>
                </DynamicIcon>
            </span>
            <span
              onClick={() => {
                setElemento("Margherita");
              }}
            >
              <DynamicIcon text={props.text.Margherita.title} type={"Margherita"}screen={props.screen}></DynamicIcon>
            </span>

            <span
              href="#"
              onClick={() => {
                setElemento("null");
                props.cambia();
              }}
            >
              <DynamicIcon
                text={props.text.lenguage}
                type={"language"}
                screen={props.screen}
              ></DynamicIcon>
            </span>
          </div>
        </nav>
      </header>
      <main className="grid">
        {props.screen == "Mobile" ? (
          <MainMobileWhite text={props.text} elem={elemento}></MainMobileWhite>
        ) : elemento == "Contact" || elemento == "Company" || elemento == "Margherita"? (
          <Modal elem={elemento} close={closeModal} text={props.text}></Modal>
        ) : (
          <MainDesk text={props.text}></MainDesk>
        )}
      </main>
      <footer>
        <p>
          © {year} - On finance SA -
          <a href="mailto:ask@onfinance.ch"> ask@onfinance.ch</a> -
          <a href="https://custerwaller.com/" target="_blank">
            {" "}
            designed by cw
          </a>
        </p>
      </footer>
    </div>
  );
}

function MainDesk(props) {
  const particelle = useRef(null);
  const [elemento, setElemento] = useState("null");
  return (
    <div className="parentWhite">
      <div className="childWhite partWhite" ref={particelle}>
        <React.Fragment>
          <Particelle
            geometry={new Curves.GrannyKnot(300).getPoints(60)}
            fov={33}
            count={100}
            lineWidth={0.3}
            dashArray={0.013}
            rotation={0.4}
          />
        </React.Fragment>
      </div>
      <div className="childWhite">
        <h1>{props.text.titlewhite}</h1>
        <div className="linkWhite">
          <nav className="navWhite">
            <a
              onClick={() => {
                setElemento("Focus");
                particelle.current.classList.add("semishow");
              }}
              className={elemento == "Focus" ? "active" : undefined}
            >
              {props.text.Focus.titleAbout}
            </a>
            <a
              onClick={() => {
                setElemento("FinancialEngineering");
                particelle.current.classList.add("semishow");
              }}
              className={
                elemento == "FinancialEngineering" ? "active" : undefined
              }
            >
              {props.text.FinancialEngineering.titleAbout}
            </a>
            <a
              onClick={() => {
                setElemento("Complementary");
                particelle.current.classList.add("semishow");
              }}
              className={elemento == "Complementary" ? "active" : undefined}
            >
              {props.text.Complementary.titleAbout}
            </a>
            <a
              onClick={() => {
                setElemento("Creativity");
                particelle.current.classList.add("semishow");
              }}
              className={elemento == "Creativity" ? "active" : undefined}
            >
              {props.text.Creativity.titleAbout}
            </a>
            <a
              onClick={() => {
                setElemento("Implementation");
                particelle.current.classList.add("semishow");
              }}
              className={elemento == "Implementation" ? "active" : undefined}
            >
              {props.text.Implementation.titleAbout}
            </a>
            <a
              onClick={() => {
                setElemento("Margherita");
                particelle.current.classList.add("semishow");
              }}
              className={elemento == "Margherita" ? "active" : undefined}
              id={"margherita"}
            >
              {props.text.Margherita.titleAbout}
            </a>
          </nav>
          {elemento == "Focus" && (
            <div id={"testo"} className="click">
              <div
                id={"closex"}
                onClick={() => {
                  setElemento("null");
                  particelle.current.classList.remove("semishow");
                }}
              >
                –
              </div>
              {props.text.Focus.desctiptionAbout}
            </div>
          )}
          {elemento == "Complementary" && (
            <div id={"testo"} className="click">
              <div
                id={"closex"}
                onClick={() => {
                  setElemento("null");
                  particelle.current.classList.remove("semishow");
                }}
              >
                –
              </div>
              {props.text.Complementary.desctiptionAbout}
            </div>
          )}
          {elemento == "Creativity" && (
            <div id={"testo"} className="click">
              <div
                id={"closex"}
                onClick={() => {
                  setElemento("null");
                  particelle.current.classList.remove("semishow");
                }}
              >
                –
              </div>
              {props.text.Creativity.desctiptionAbout}
            </div>
          )}
          {elemento == "Implementation" && (
            <div id={"testo"} className="click">
              <div
                id={"closex"}
                onClick={() => {
                  setElemento("null");
                  particelle.current.classList.remove("semishow");
                }}
              >
                –
              </div>
              {props.text.Implementation.desctiptionAbout}
            </div>
          )}
          {elemento == "FinancialEngineering" && (
            <div id={"testo"} className="click">
              <div
                id={"closex"}
                onClick={() => {
                  setElemento("null");
                  particelle.current.classList.remove("semishow");
                }}
              >
                –
              </div>
              {props.text.FinancialEngineering.desctiptionAbout}
            </div>
          )}

          {/* {elemento == "Margherita" && (
            <div id={"testo"} className="click">
              <div
                id={"closex"}
                onClick={() => {
                  setElemento("null");
                  particelle.current.classList.remove("semishow");
                }}
              >
                –
              </div>
              {props.text.Margherita.text}
              <a
                href="http://fondazionemargherita.ch"
                id="linkMargherita"
                target="_blank"
              >
                Fondazione Margherita
              </a>
              {props.text.Margherita.text2}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}
function Modal(props) {
  return (
    <div className="modal">
      <a href="#" onClick={() => props.close()} className="logoClose">
        {props.text.Goback}
      </a>
      {props.elem == "Company" ? <Company text={props.text}></Company> : null}

      {props.elem == "Contact" ? <Contact></Contact> : null}

      {props.elem == "Margherita" ? <Margherita text={props.text.Margherita}></Margherita> : null}
    </div>
  );
}
