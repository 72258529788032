import { forwardRef } from "react";
const Contact = forwardRef((props, ref) => (
  <>
    <ol className="adress adress1" id="Contact" ref={ref}>
      <a href="https://goo.gl/maps/hjG6wNRgFekdZwjM7" target="_blank">
        <li>
          <b>Lugano</b>
        </li>
        <li> Piazza Manzoni 3 </li>
        <li>CH-6900 Lugano</li>
        <li>Switzerland</li>
      </a>
      <div className="space"></div>
      <li>
        <a href="tel:+41919102060">+41 91 910 20 60</a>
      </li>
      <li>
        <a href="mailto:ask@onfinance.ch">ask@onfinance.ch</a>
      </li>
    </ol>
    <ol className="adress adress2">
      <a href="https://goo.gl/maps/wvbd9zJ15RqCxYB38" target="_blank">
        <li>
          <b>Geneva </b>
        </li>
        <li>Rue du Jeu-de-l’Arc 15 </li>
        <li>CH-1207 Geneva</li>
        <li>Switzerland</li>
      </a>
      <div className="space"></div>
      <li>
        <a href="tel:+41791049265">+41 79 104 92 65</a>
      </li>
      <li>
        <a href="mailto:ask@onfinance.ch">ask@onfinance.ch</a>
      </li>
    </ol>
  </>
));
export default Contact;
