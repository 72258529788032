import React, { useRef } from "react";
import Particelle from "./Particelle";
import DynamicIcon from "./DynamicIcon";
import { Curves } from "three/examples/jsm/curves/CurveExtras";
export default function HomeWhite(props) {
  const particelle = useRef(null);
  const textLeft = useRef(null);
  const textRight = useRef(null);
  const textUnder = useRef(null);
  const textMiddleLeft = useRef(null);
  const textMiddleTop = useRef(null);

  const handleMouseOver = (event) => {
    particelle.current.classList.remove("hidden");
    textLeft.current.classList.remove("hidden");
    textUnder.current.classList.remove("hidden");
    textRight.current.classList.remove("hidden");
    textMiddleLeft.current.classList.remove("hidden");
    textMiddleTop.current.classList.remove("hidden");
  };
  const handleMouseOut = (event) => {
    particelle.current.classList.add("hidden");
    textLeft.current.classList.add("hidden");
    textUnder.current.classList.add("hidden");
    textRight.current.classList.add("hidden");
    textMiddleLeft.current.classList.add("hidden");
    textMiddleTop.current.classList.add("hidden");
  };
  return (
    <div
      className="homeWhite"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <header className="grid">
        <nav>
          <div className="navIconHome">
            <span href="#" onClick={props.cambia}>
              <DynamicIcon
                text={props.text.lenguage}
                type={"language"}
              ></DynamicIcon>
            </span>
          </div>
        </nav>
      </header>

      <div className="parentStart" onClick={() => props.changePage("white")}>
        <div className="childStart part" ref={particelle}>
          <Particelle
            geometry={new Curves.GrannyKnot(300).getPoints(60)}
            fov={
              props.screen == "Desktop"
                ? 30
                : props.screen == "Mobile"
                ? 30
                : 30
            }
            count={
              props.screen == "Desktop"
                ? 170
                : props.screen == "Mobile"
                ? 150
                : 140
            }
            lineWidth={
              props.screen == "Desktop"
                ? 0.2
                : props.screen == "Mobile"
                ? 0.2
                : 0.14
            }
            dashArray={
              props.screen == "Desktop"
                ? 0.009
                : props.screen == "Mobile"
                ? 0.009
                : 0.008
            }
            rotation={0}
          />
        </div>
        <div className="childStart">
          <div className="homeTitle">
            <h1>{props.text.titlewhite}</h1>
            <p>{props.text.subtitlewhite} </p>
            <div className="leftPopUp hidden" ref={textLeft}>
              {props.text.Focus.titleAbout}
            </div>
            <div className="underPopUp hidden" ref={textUnder}>
              {props.text.Complementary.titleAbout}
            </div>
            <div className="middleLeftPopUp hidden" ref={textRight}>
              {props.text.Creativity.titleAbout}
            </div>
            <br />
            <div className="rightPopUp hidden" ref={textMiddleLeft}>
              {props.text.FinancialEngineering.titleAbout}
            </div>
            <div className="middleTopPopUp hidden" ref={textMiddleTop}>
              {props.text.Implementation.titleAbout}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
