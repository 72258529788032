import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import globe from "../assets/globe.svg";
import globeOrange from "../assets/orange/globeOrange.svg";
import user from "../assets/user.svg";
import userOrange from "../assets/orange/userOrange.svg";
export default function DynamicIcon(props) {
  const [hover, setHover] = useState(false);
  const handleMouseOver = (event) => {
    setHover(true);
  };
  const handleMouseOut = (event) => {
    setHover(false);
  };
  return (
    <a
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      target={props.type == "login" ? "_blank" : "_self"}
      href={
        props.type == "login"
          ? "https://platform.onfinance.ch/products_devel"
          : "#"
      }
      className={hover ? "activeOrange" : undefined}
    >
      <img
        src={
          props.type == "language"
            ? hover
              ? globeOrange
              : globe
            : hover
            ? userOrange
            : user
        }
        className="icon"
      ></img>
      {props.text}
    </a>
  );
}
